import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Project } from 'models';
import { useTranslation } from 'react-i18next';

export const FollowConfirmation = ({
  project,
  setIsChangingFollow,
  handelChangeFollow,
}: {
  project: Project;
  setIsChangingFollow: (isChangingFollow: boolean) => void;
  handelChangeFollow: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Modal
      title={!project.isFollowed ? t('Save.Title') : t('SaveRemove.Title')}
      handleKeyEsc={() => setIsChangingFollow(false)}
      style={{ maxWidth: 600 }}
    >
      <div>
        {!project.isFollowed
          ? t('Save.ConfirmText')
          : t('SaveRemove.ConfirmText')}
      </div>

      <Buttons style={{ marginTop: 30 }}>
        <ButtonSecondary
          type="button"
          onClick={() => setIsChangingFollow(false)}
        >
          {t('Common.Form.Cancel')}
        </ButtonSecondary>

        <Button type="button" onClick={() => handelChangeFollow()}>
          {t('Common.UI.Yes')}
        </Button>
      </Buttons>
    </Modal>
  );
};

FollowConfirmation.displayName = 'FollowConfirmation';
