import ReactGA from 'react-ga4';
import { config } from '../config';
import { getCookie, removeCookie, storeCookie } from '../utils';
var GaConsentCookieName = 'consent-ga';
var initialize = function (isEnabled) {
    if (isEnabled === void 0) { isEnabled = true; }
    if (isEnabled) {
        ReactGA.initialize([
            {
                trackingId: config.googleAnalytics.key,
                // NOTE: Enable when debugging
                // Read more: https://support.google.com/analytics/answer/7201382?hl=en
                // gtagOptions: { debug_mode: true },
            },
        ]);
    }
    else {
        // Remove ga cookies
        removeCookie('_ga');
        removeCookie("_ga_".concat(config.googleAnalytics.key.replace(/G-/g, '')));
    }
};
var getHasGivenConsent = function () {
    var gaConsentCookie = getCookie(GaConsentCookieName);
    return !!gaConsentCookie;
};
var getHasSavedConsent = function () {
    var gaConsentCookie = getCookie(GaConsentCookieName);
    return gaConsentCookie !== undefined;
};
var setHasGivenConsent = function (hasGivenConsent) {
    storeCookie(GaConsentCookieName, hasGivenConsent.toString());
};
export default {
    initialize: initialize,
    getHasSavedConsent: getHasSavedConsent,
    getHasGivenConsent: getHasGivenConsent,
    setHasGivenConsent: setHasGivenConsent,
};
