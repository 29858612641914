import { LoginWithLinkConfirmation as LoginWithLinkConfirmationFeature } from 'features/login/LoginWithLinkConfirmation';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const LoginWithLinkConfirmation = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('LoginWithLink.Title')}>
      <LoginWithLinkConfirmationFeature />
    </ViewCentered>
  );
};

LoginWithLinkConfirmation.displayName = 'LoginWithLinkConfirmation';
