import { MultiSelect } from '@dimatech/shared/lib/components/form';
import { useGetCustomersQuery } from 'api/private/searchApi';
import { Node, PublishedCustomer } from 'models';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SelectCustomers = ({
  customers,
  setCustomers,
  inverted = false,
}: {
  customers?: string[];
  setCustomers: (customers: string[]) => void;
  inverted?: boolean;
}): JSX.Element | null => {
  const { t } = useTranslation();

  const { data } = useGetCustomersQuery();
  const [nodes, setNodes] = useState<Node<PublishedCustomer>[]>();

  const handleChange = (selectedNodes: Node<PublishedCustomer>[]) => {
    setCustomers(selectedNodes.map((node) => node.id));
  };

  useEffect(() => {
    setNodes(
      data?.map((customer) => ({
        id: customer.customerId,
        name: customer.customerName,
        parentId: null,
        payload: customer as PublishedCustomer,
      }))
    );
  }, [data]);

  return (
    <>
      {nodes && (
        <MultiSelect
          inverted={inverted}
          style={{
            minWidth: 200,
          }}
          placeholder={t('Search.Customers')}
          options={nodes}
          setSelected={(selectedNodes) => {
            handleChange(selectedNodes);
          }}
          showSelectAll={false}
          selected={nodes.filter((node) => customers?.includes(node.id))}
        />
      )}
    </>
  );
};

SelectCustomers.displayName = 'SelectCustomers';
