import { Newsletter as NewsletterFeature } from 'features/home/Newsletter';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Newsletter = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View title={t('Newsletter.Title')}>
      <NewsletterFeature />
    </View>
  );
};

Newsletter.displayName = 'Newsletter';
