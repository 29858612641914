import { FollowedProjects as FollowedProjectsFeature } from 'features/projects/FollowedProjects';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const FollowedProjects = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View title={t('FollowedProjects.Title')}>
      <FollowedProjectsFeature />
    </View>
  );
};

FollowedProjects.displayName = 'FollowedProjects';
