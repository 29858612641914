import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Phase, SearchFilter } from 'models';
import { RootState } from 'store';

type State = {
  filter: SearchFilter;
};

const initialState: State = {
  filter: {},
};

export const { reducer: piosPrivateReducer, actions: piosPrivateActions } =
  createSlice({
    name: 'pios-private',
    initialState,
    reducers: {
      setFilter: (state, action: PayloadAction<SearchFilter>) => {
        state.filter = action.payload;
      },
      setFilterPhase: (state, action: PayloadAction<Phase[] | undefined>) => {
        state.filter.phases = action.payload;
      },
      setFilterTagIds: (state, action: PayloadAction<string[]>) => {
        state.filter.tagIds = action.payload;
      },
      resetFilter: (state) => {
        state.filter = initialState.filter;
      },
    },
    extraReducers: (build) => {
      build.addCase(appActions.resetApplication, () => initialState);
    },
  });

export const selectFilter = (state: RootState): SearchFilter =>
  state.piosPrivate.filter;
