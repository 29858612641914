import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { ApplicationContext } from '@dimatech/shared/lib/application';
import { EnvironmentBanner } from '@dimatech/shared/lib/components/dev-tools';
import { SwitchTheme } from '@dimatech/shared/lib/components/workspace';
import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import img_flag_en from 'assets/images/flag-en.png';
import img_flag_sv from 'assets/images/flag-sv.png';
import { dark } from 'assets/themes/dark';
import { light } from 'assets/themes/light';
import { Container } from 'components/Container';
import { Language } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { BsClipboardData, BsEnvelope, BsPower, BsSearch } from 'react-icons/bs';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';

export const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken, webToken, logout } = useContext(AuthenticationContext);
  const { locale, changeLocale } = useContext(LocalizationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const application = useContext(ApplicationContext);

  return (
    <Style>
      <Container>
        <Logo onClick={() => navigate('/')}>
          {application.title}
          <EnvironmentBanner />
        </Logo>

        <div>
          <Navigation>
            <NavigationItem $selected={location.pathname.startsWith('/search')}>
              <BsSearch className="hide-l" />
              <Link to="/search">{t('Navigation.Search')}</Link>
            </NavigationItem>

            <NavigationItem
              $selected={location.pathname.startsWith('/followed-projects')}
            >
              <BsClipboardData className="hide-l" />
              <Link to="/followed-projects">
                {t('Navigation.FollowedProjects')}
              </Link>
            </NavigationItem>

            <NavigationItem
              $selected={location.pathname.startsWith('/contacted-projects')}
            >
              <BsEnvelope className="hide-l" />
              <Link to="/contacted-projects">
                {t('Navigation.ContactedProjects')}
              </Link>
            </NavigationItem>

            {(accessToken.isLoggedIn || webToken.isLoggedIn) && (
              <NavigationItem>
                <BsPower />
                <span onClick={() => logout()}>{t('Navigation.Logout')}</span>
              </NavigationItem>
            )}

            <Button
              className="hide-l"
              text={t('Navigation.Newsletter')}
              handleClick={() => navigate('/newsletter')}
            />

            <NavigationItem className="show-l">
              <Link to="/newsletter"> {t('Navigation.Newsletter')}</Link>
            </NavigationItem>
          </Navigation>

          <div style={{ display: 'flex', gap: 10 }}>
            <SwitchTheme dark={dark.name} light={light.name} />

            <ChangeLanguage
              type="button"
              onClick={() =>
                changeLocale(
                  locale === Language.English
                    ? Language.Swedish
                    : Language.English
                )
              }
            >
              {locale === Language.English ? (
                <img src={img_flag_sv} alt="Swedish flag" />
              ) : (
                <img src={img_flag_en} alt="English flag" />
              )}
            </ChangeLanguage>
          </div>
        </div>
      </Container>
    </Style>
  );
};

Header.displayName = 'Header';

const Logo = styled.div`
  margin-right: auto;
  margin-left: 0;

  height: 50px;
  display: flex;
  align-items: center;

  cursor: pointer;

  font: normal 300 2.4rem / 32px 'Roboto', sans-serif !important;

  > span {
    margin-left: 10px;
    font: normal 300 2.4rem / 32px 'Roboto', sans-serif !important;
  }

  color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
`;

const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  > div {
    padding: 0 30px;

    > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      @media (max-width: ${Breakpoints.large}) {
        font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
      }

      @media (max-width: ${Breakpoints.medium}) {
        gap: 10px;
      }

      @media (max-width: ${Breakpoints.medium}) {
        font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.base};
        gap: 20px;
        flex-basis: 100%;
        justify-content: space-between;

        padding: 10px;
        margin-bottom: 15px;

        background-color: ${({ theme }: { theme: Theme }) =>
          theme.colors.primary};
        color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
      }
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: ${Breakpoints.large}) {
    gap: 15px;
  }

  @media (max-width: ${Breakpoints.medium}) {
    margin-bottom: 5px;
    gap: 10px;
  }

  @media (max-width: ${Breakpoints.small}) {
    gap: 5px 10px;
    flex-grow: 10;
  }
`;

const NavigationItem = styled.div<{
  readonly $selected?: boolean;
}>`
  svg {
    margin-right: 7px;
    flex-shrink: 0;

    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.l};
    color: ${(props) =>
      props.$selected ? props.theme.colors.primary : 'unset'};
  }

  svg {
    vertical-align: middle;
  }

  &:hover {
    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
  }

  a,
  span {
    cursor: pointer;
    border: none;

    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};

    color: ${(props) =>
      props.$selected ? props.theme.colors.primary : 'unset'};

    @media (max-width: ${Breakpoints.small}) {
      color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
    }
  }

  @media (max-width: ${Breakpoints.large}) {
    svg {
      margin-right: 4px;
    }
  }

  @media (max-width: ${Breakpoints.medium}) {
    &:hover {
      color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
    }

    a,
    span {
      color: ${(props) =>
        props.$selected ? props.theme.colors.onPrimary : 'unset'};
      font-weight: ${(props) => (props.$selected ? 'bold' : 'unset')};
    }
  }
`;

const ChangeLanguage = styled.button`
  justify-self: flex-end;
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    margin-top: 5px;
    height: 24px;
  }
`;
