import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled, { CSSProperties } from 'styled-components';

export const Button = ({
  text,
  light,
  color,
  disabled,
  handleClick,
  className,
  style,
}: {
  text: string;
  light?: boolean;
  color?: string;
  disabled?: boolean;
  handleClick?: (e: React.SyntheticEvent) => void;
  className?: string;
  style?: CSSProperties;
}): JSX.Element => {
  return (
    <>
      {light ? (
        <StyledLightButton
          color={color}
          onClick={handleClick}
          type="button"
          className={className}
          style={style}
          disabled={disabled}
        >
          {text}
        </StyledLightButton>
      ) : (
        <StyledButton
          color={color}
          onClick={handleClick}
          type="button"
          className={className}
          style={style}
          disabled={disabled}
        >
          {text}
        </StyledButton>
      )}
    </>
  );
};

export const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: 20px;
  gap: 20px;
`;

export const ButtonsHero = styled(Buttons)`
  margin-top: 0;
`;

const StyledButton = styled.button<{
  readonly color?: string;
}>`
  color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
  background-color: ${(props) => props.color ?? props.theme.colors.primary};
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};

  border-radius: 5px;
  border: none;

  cursor: pointer;

  width: fit-content;
  min-width: 150px;
  min-height: 40px;

  padding: 0 15px;

  &:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.3));
  }

  @media (min-width: ${Breakpoints.medium}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.base};
  }
`;

const StyledLightButton = styled(StyledButton)<{
  readonly color?: string;
}>`
  color: ${(props) => props.color ?? props.theme.colors.primary};
  border: solid 2px ${(props) => props.color ?? props.theme.colors.primary};
  border-radius: 4px;

  box-shadow: 2px 1000px 1px #fff inset;
`;
