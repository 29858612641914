import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import GoogleAnalytics from '../../tracking/googleAnalytics';
import { CookieConsentSelection } from './CookieConsentSelection';
export var CookieConsent = function () {
    var _a = useState(GoogleAnalytics.getHasSavedConsent()), hasSavedConsent = _a[0], setHasSavedConsent = _a[1];
    var hasGivenConsent = GoogleAnalytics.getHasGivenConsent();
    GoogleAnalytics.initialize(hasGivenConsent);
    if (hasSavedConsent) {
        return null;
    }
    return (_jsx(CookieConsentSelection, { hasSavedConsent: hasSavedConsent, setHasSavedConsent: setHasSavedConsent }));
};
CookieConsent.displayName = 'CookieConsent';
