import { base, Color, Theme, ThemeType } from '@dimatech/shared/lib/themes';

// Light theme, only overrides necessary properties
export const light: Theme = {
  ...base,
  name: 'Pios Public Light',
  type: ThemeType.Light,

  size: {
    viewMaxWidth: '1400px',
  },

  fonts: {
    base: "normal 200 1.6rem/23px 'Heebo', sans-serif",
    title: "normal 200 2.6rem/32px 'Heebo', sans-serif",
    h1: "normal 500 3.2rem/28px 'Heebo', sans-serif",
    h2: "normal 500 3.2rem/28px 'Heebo', sans-serif",
    h3: "normal 500 2.4rem/24px 'Heebo', sans-serif",
    h4: "normal 300 1.6rem/24px 'Heebo', sans-serif",

    size: {
      base: '1.6rem',
      s: '1.4rem',
      xs: '1.2rem',
      xxs: '1.1rem',
      l: '1.8rem',
      xl: '2.4rem',
      xxl: '3.2rem',
    },
  },

  colors: {
    ...base.colors,

    background: Color.White,
    onBackground: Color.Grey10,

    primary: Color.DimatechBlue,
    primaryVariant: Color.DimiosBlueDarker,
    onPrimary: Color.White,

    secondary: Color.DimiosBlueDarker,
    secondaryVariant: Color.SeaGreen,
    onSecondary: Color.White,

    secondaryDim: Color.CaribbeanGreenDark,
    onSecondaryDim: Color.Grey80,

    surfaceVariant: Color.Grey20,
    onSurfaceVariant: Color.White,

    surface: Color.Grey95,
    onSurface: Color.Grey20,

    chart: {
      ...base.colors.chart,

      primary: Color.CaribbeanGreen,
      primaryHighlight: Color.CaribbeanGreenDark,

      secondary: Color.DimiosBlue,
      secondaryHighlight: Color.DimiosBlueDark,
    },
  },
};
