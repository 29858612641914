import { boilerplateCss, helpersCss } from '@dimatech/shared/lib/themes';
import { createGlobalStyle } from 'styled-components';
import { generalStyling } from './style';

export const GlobalStyles = createGlobalStyle`
  // NOTE: Do not add styles here, add them to css section and import them here
  ${boilerplateCss}
  ${helpersCss}
  ${generalStyling}
`;
