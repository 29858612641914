import { axiosBaseQuery } from '@dimatech/shared/lib/middleware/axiosBaseQuery';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

export enum PublicCacheTags {
  Overview = 'Overview',
  OverviewByTag = 'OverviewByTag',
}

initializeAxiosConfig({ ...config.axiosDefaults });
initializeAxiosResponseInterceptor();

// Api
export const piosPublicApi = createApi({
  reducerPath: 'piosPublicApi',
  keepUnusedDataFor: 3600,
  tagTypes: [Object.values(PublicCacheTags).join(',')],
  baseQuery: axiosBaseQuery({
    baseUrl: config.apiUrl.product.current,
  }),
  endpoints: () => ({}),
});
