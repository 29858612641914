import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { CurrencyIcon } from '@dimatech/shared/lib/components/CurrencyIcon';
import { Number } from '@dimatech/shared/lib/components/Number';
import { Tags } from '@dimatech/shared/lib/components/Tag';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  useGetOverviewByTagQuery,
  useGetOverviewQuery,
} from 'api/public/overviewApi';
import img_hero from 'assets/images/nature-1-hero.jpg';
import { Button, Buttons } from 'components/Button';
import { Container } from 'components/Container';
import { HeroSubTitle, HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { Section } from 'components/Section';
import { config } from 'config';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BsClipboardData, BsDiagram3 } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { formatAsCurrency, formatAsNumber } from 'utils';

export const Home = withTheme(({ theme }: { theme: Theme }): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken } = useContext(AuthenticationContext);

  const navigate = useNavigate();

  const isAreasOfOperationEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isAreasOfOperationEnabled];

  const { data: overview } = useGetOverviewQuery();
  const { data: overviewByTag } = useGetOverviewByTagQuery(
    isAreasOfOperationEnabledFlagOn ?? skipToken
  );

  return (
    <>
      <HeroProduct image={img_hero}>
        <HeroTitle>{t('Home.Title')}</HeroTitle>
        <HeroSubTitle>{t('Home.SubTitle')}</HeroSubTitle>
        <HeroText>{t('Home.Text')}</HeroText>
      </HeroProduct>

      <StatisticsStyle color={theme.colors.primary}>
        <Container>
          <Number
            icon={<BsDiagram3 />}
            color={theme.colors.primary}
            text={t('Home.Statistics.NoOfOrganisations')}
            formattedValue={`${formatAsNumber(overview?.totalCustomers)}`}
          />

          <Number
            icon={<BsClipboardData />}
            color={theme.colors.primary}
            text={t('Home.Statistics.NoOfProjects')}
            formattedValue={`${formatAsNumber(overview?.totalProjects)}`}
          />

          <Number
            icon={<CurrencyIcon currency={accessToken.locale.currency} />}
            color={theme.colors.primary}
            text={t('Home.Statistics.TotalBudget', {
              currency: t(
                `Common.Currency.${accessToken.locale.currency}.Name`
              ),
            })}
            formattedValue={`${formatAsCurrency(overview?.totalBudget)}`}
          />

          {isAreasOfOperationEnabledFlagOn &&
            overviewByTag &&
            overviewByTag.filter((tag) => tag.displayName).length > 0 && (
              <TagsStyle>
                <div>
                  <Tags
                    tags={overviewByTag
                      .filter((tag) => tag.displayName)
                      .map((tag) => ({
                        ...tag,
                        displayName: `${tag.displayName}: ${tag.totalProjects}`,
                      }))}
                    onClick={(tag) => navigate(`/search?tag=${tag.id}`)}
                  />
                </div>
                <div>{t('Home.Statistics.NoOfProjectsByTag')}</div>
              </TagsStyle>
            )}
        </Container>
      </StatisticsStyle>

      <Section>
        <Container>
          <TextStyle>
            <div>
              <Trans i18nKey="Home.Text2" />

              <Buttons>
                <Button
                  text={t('BookDemoButton.Text')}
                  handleClick={() => {
                    trackEvent('email_book_demo', {
                      product: 'Pios',
                    });

                    window.location.href = `mailto:sales@dimatech.se?subject=${t(
                      'RegisterButton.MailSubject',
                      { product: 'Pios' }
                    )}`;
                  }}
                />

                <Button
                  text={t('RegisterButton.Text')}
                  handleClick={() =>
                    (window.location.href = `${config.products.pios.url}/register`)
                  }
                  light
                />
              </Buttons>
            </div>

            <div>
              <Trans i18nKey="Home.Text3" />
            </div>
          </TextStyle>
        </Container>
      </Section>
    </>
  );
});

Home.displayName = 'Home';

const TextStyle = styled.div`
  > div {
    margin-bottom: 60px;
  }
`;

const TagsStyle = styled.div`
  > div:first-of-type {
    display: flex;
    flex-direction: row;
  }

  > div:last-of-type {
    display: flex;
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};
  }
`;

const StatisticsStyle = styled.div<{
  readonly color: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  margin-top: 40px;
  margin-bottom: 20px;

  width: 100%;

  padding: 50px 50px 50px 40px;

  background: linear-gradient(270deg, ${(props) => props.color} 0%, #000 100%);

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;

    width: 100%;
    margin: 0 50px;
    padding: 0 50px;
    gap: 15px;

    > div {
      flex-basis: 20%;

      &:last-of-type {
        flex-grow: 2;
        flex-shrink: 0;
        flex-basis: 40%;
      }

      > div {
        justify-content: flex-start !important;
        align-items: center !important;
        text-align: left !important;
      }
    }
  }

  @media (min-width: ${Breakpoints.medium}) {
    padding-left: 60px !important;
  }

  @media (max-width: ${Breakpoints.medium}) {
    > div {
      gap: 50px;
    }
  }
`;
