import {
  features_core_en,
  features_core_sv,
} from '@dimatech/features-core/lib/localization/';
import { defaultOptions } from '@dimatech/shared/lib/localization';
import shared_en from '@dimatech/shared/lib/localization/lang/en.json';
import shared_sv from '@dimatech/shared/lib/localization/lang/sv.json';
import { Language, Translations } from '@dimatech/shared/lib/models';
import { enGB as en, sv } from 'date-fns/locale';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import { mergeJson } from 'utils';

import pios_public_en from './lang/en.json';
import pios_public_sv from './lang/sv.json';

registerLocale(Language.English, en);
registerLocale(Language.Swedish, sv);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(defaultOptions);

// Merge all shared language files with the language files in this app
i18n.addResourceBundle(
  Language.Swedish,
  Translations.Common,
  mergeJson(features_core_sv, [shared_sv, pios_public_sv])
);

i18n.addResourceBundle(
  Language.English,
  Translations.Common,
  mergeJson(features_core_en, [shared_en, pios_public_en])
);

export default i18n;
