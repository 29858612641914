import { LoginWithAccount as LoginWithAccountFeature } from 'features/login/LoginWithAccount';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const LoginWithAccount = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('Login.Title')}>
      <LoginWithAccountFeature />
    </ViewCentered>
  );
};

LoginWithAccount.displayName = 'LoginWithAccount';
