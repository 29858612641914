import { piosPrivateApi, PrivateCacheTags } from 'api/piosPrivateApi';
import {
  PaginatedResult,
  Paginator,
  Project,
  PublishedCustomer,
  SearchFilter,
  SortDirection,
  Tag,
} from 'models';

const searchApi = piosPrivateApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Search
     */
    search: build.query<
      PaginatedResult<Project>,
      { filter: SearchFilter; paginator: Paginator }
    >({
      query: ({
        filter,
        paginator,
      }: {
        filter: SearchFilter;
        paginator: Paginator;
      }) =>
        `web/projects?pageNumber=${paginator.page}&pageSize=${
          paginator.pageSize
        }&orderBy.propertyName=${paginator.orderBy}&orderBy.direction=${
          paginator.orderDirection
        }${
          filter.customerIds && filter.customerIds.length > 0
            ? filter.customerIds
                .map((customerId) => `&filter.customerIds=${customerId}`)
                .join('')
            : ''
        }${
          filter.phases && filter.phases.length > 0
            ? filter.phases
                .map((phase) => `&filter.projectPhases=${phase}`)
                .join('')
            : ''
        }${filter.searchTerm ? `&filter.searchTerm=${filter.searchTerm}` : ''}${
          filter.tagIds && filter.tagIds.length > 0
            ? filter.tagIds
                .map((tagId) => `&filter.nationalTags=${tagId}`)
                .join('')
            : ''
        }`,
      providesTags: [PrivateCacheTags.Search],
    }),

    /**
     * Get project
     */
    getProject: build.query<
      Project | undefined,
      {
        projectId: string;
        _userId: string;
      }
    >({
      query: ({ projectId, _userId }: { projectId: string; _userId: string }) =>
        `web/projects?pageNumber=1&pageSize=10&orderBy.propertyName=title&orderBy.direction=${SortDirection.Asc}&filter.projectId=${projectId}`,
      providesTags: [PrivateCacheTags.Project],
      transformResponse: (result: { records: Project[] }) => {
        return result.records && result.records.length > 0
          ? result.records[0]
          : undefined;
      },
    }),

    /**
     * Get common tags
     */
    getCommonTags: build.query<Tag[], void>({
      query: () => 'web/tags/national',
      transformResponse: (result: Tag[]) => {
        return result.sort((a, b) =>
          (a.displayName as string) > (b.displayName as string) ? 1 : -1
        );
      },
      providesTags: [PrivateCacheTags.CommonTag],
    }),

    /**
     * Get customers tags
     */
    getCustomers: build.query<PublishedCustomer[], void>({
      query: () => 'web/projects/customers',
      transformResponse: (result: PublishedCustomer[]) => {
        return result.sort((a, b) =>
          (a.customerName as string) > (b.customerName as string) ? 1 : -1
        );
      },
      providesTags: [PrivateCacheTags.PublishedCustomer],
    }),

    /**
     * Get customer logo
     */
    getLogo: build.query<PublishedCustomer[], void>({
      query: () => 'web/projects/customers',
      transformResponse: (result: PublishedCustomer[]) => {
        return result.sort((a, b) =>
          (a.customerName as string) > (b.customerName as string) ? 1 : -1
        );
      },
      providesTags: [PrivateCacheTags.PublishedCustomer],
    }),
  }),
});

export const {
  useGetProjectQuery,
  useLazySearchQuery,
  useGetCommonTagsQuery,
  useGetCustomersQuery,
} = searchApi;
