import { LoginWithLink as LoginWithLinkFeature } from 'features/login/LoginWithLink';
import { useTranslation } from 'react-i18next';
import { ViewCentered } from 'views';

export const LoginWithLink = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ViewCentered title={t('LoginWithLink.Title')}>
      <LoginWithLinkFeature />
    </ViewCentered>
  );
};

LoginWithLink.displayName = 'LoginWithLink';
