import { appReducer } from '@dimatech/features-core/lib/api/appSlice';
import { featuresCoreApi } from '@dimatech/features-core/lib/api/featuresCoreApi';
import { combineReducers } from '@reduxjs/toolkit';
import { piosPrivateApi } from 'api/piosPrivateApi';
import { piosPublicApi } from 'api/piosPublicApi';
import { piosPrivateReducer } from './api/piosPrivateSlice';
import { customer } from './api/private/customerSlice';

export const rootReducer = combineReducers({
  app: appReducer,
  customer,
  piosPrivate: piosPrivateReducer,
  [piosPublicApi.reducerPath]: piosPublicApi.reducer,
  [piosPrivateApi.reducerPath]: piosPrivateApi.reducer,
  [featuresCoreApi.reducerPath]: featuresCoreApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
