import {
  Button,
  Buttons,
  ButtonSecondary,
} from '@dimatech/shared/lib/components/form';
import { Modal } from '@dimatech/shared/lib/components/modal';
import { Project } from 'models';
import { useTranslation } from 'react-i18next';

export const ContactConfirmation = ({
  project,
  setIsChangingContact,
  handleChangeContact,
}: {
  project: Project;
  setIsChangingContact: (isChangingContact: boolean) => void;
  handleChangeContact: () => void;
}): JSX.Element | null => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Contact.Title')}
      handleKeyEsc={() => setIsChangingContact(false)}
      style={{ maxWidth: 600 }}
    >
      <div>{t('Contact.ConfirmText')}</div>

      <Buttons style={{ marginTop: 30 }}>
        <ButtonSecondary
          type="button"
          onClick={() => setIsChangingContact(false)}
        >
          {t('Common.Form.Cancel')}
        </ButtonSecondary>

        <Button type="button" onClick={() => handleChangeContact()}>
          {t('Common.UI.Yes')}
        </Button>
      </Buttons>
    </Modal>
  );
};

ContactConfirmation.displayName = 'ContactConfirmation';
