import { piosPrivateApi, PrivateCacheTags } from 'api/piosPrivateApi';
import { PaginatedResult, Paginator, Project } from 'models';

const followApi = piosPrivateApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * List followed projects
     */
    getFollowed: build.query<
      PaginatedResult<Project>,
      { paginator: Paginator; _userId: string }
    >({
      query: ({
        paginator,
        _userId,
      }: {
        paginator: Paginator;
        _userId: string;
      }) =>
        `web/projects?pageNumber=${paginator.page}&pageSize=${paginator.pageSize}&orderBy.propertyName=${paginator.orderBy}&orderBy.direction=${paginator.orderDirection}&filter.followedOnly=true`,
      providesTags: [PrivateCacheTags.Followed],
    }),

    /**
     * Follow project
     */
    followProject: build.mutation({
      query: (projectId: string) => ({
        url: `web/projects/follow/${projectId}`,
        method: 'PUT',
      }),
      invalidatesTags: [PrivateCacheTags.Followed, PrivateCacheTags.Project],
    }),

    /**
     * Unfollow project
     */
    unfollowProject: build.mutation({
      query: (projectId: string) => ({
        url: `web/projects/unfollow/${projectId}`,
        method: 'PUT',
      }),
      invalidatesTags: [PrivateCacheTags.Followed, PrivateCacheTags.Project],
    }),
  }),
});

export const {
  useLazyGetFollowedQuery,
  useFollowProjectMutation,
  useUnfollowProjectMutation,
} = followApi;
