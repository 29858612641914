import { Input } from '@dimatech/shared/lib/components/form';
import { trackEvent } from '@dimatech/shared/lib/tracking';
import { isValidEmail } from '@dimatech/shared/lib/utils';
import img_hero from 'assets/images/about-hero.jpg';
import { Button, ButtonsHero } from 'components/Button';
import { HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

export const Newsletter = (): JSX.Element => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const url =
    'https://digitalforvaltning.us19.list-manage.com/subscribe/post-json?u=e1a7af615d344a603fcae76b5&id=40c21fe5d4&f_id=0092b1e4f0';

  return (
    <HeroProduct image={img_hero}>
      <HeroTitle>{t('Newsletter.Title')}</HeroTitle>
      <HeroText>{t('Newsletter.Text')}</HeroText>

      <ButtonsHero style={{ width: '100%' }}>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <div style={{ width: '100%' }}>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                style={{ width: '100%', maxWidth: 480, height: 45 }}
                invalid={status === 'error'}
                placeholder={t('Newsletter.EmailPlaceholder')}
              />

              <div style={{ marginBottom: 10, marginTop: 5 }}>
                {status === 'sending' && (
                  <HeroText>{t('Newsletter.Sending')}</HeroText>
                )}

                {status === 'error' && (
                  <HeroText
                    dangerouslySetInnerHTML={{ __html: message as string }}
                  />
                )}

                {status === 'success' && (
                  <HeroText
                    dangerouslySetInnerHTML={{ __html: message as string }}
                  />
                )}
              </div>

              <Button
                text={t('Newsletter.Send')}
                handleClick={() => {
                  if (email && isValidEmail(email)) {
                    trackEvent('newsletter_subscribe');
                    subscribe({ EMAIL: email });
                  }
                }}
              />
            </div>
          )}
        />
      </ButtonsHero>
    </HeroProduct>
  );
};

Newsletter.displayName = 'Newsletter';
