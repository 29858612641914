import { Color, Theme } from '@dimatech/shared/lib/themes';
import { CSSProperties } from 'react';
import styled from 'styled-components';

export const DimatechLogoFooter = ({ style }: { style?: CSSProperties }) => {
  return (
    <LogoFooter
      style={{ ...style, cursor: 'pointer' }}
      onClick={() => (window.location.href = 'https://www.dimatech.se')}
    >
      <DimatechLogoContent />
    </LogoFooter>
  );
};

const DimatechLogoContent = () => {
  return (
    <>
      <div>D</div>
      <div>Dimatech</div>
    </>
  );
};

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;

  font: ${({ theme }: { theme: Theme }) => theme.fonts.title};

  a {
    border: none;
    text-decoration: none;
  }

  div {
    padding: 0;
    height: fit-content;
    width: fit-content;
  }

  div:first-of-type {
    color: ${Color.DimatechBlue};
    font-weight: 500;
    font-size: 60px;
  }

  div:last-of-type {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
    margin-left: 3px;
  }
`;

const LogoFooter = styled(Logo)``;
