import styled from 'styled-components';
import { ContainerWrapper } from './Container';

export const Section = styled(ContainerWrapper)`
  > div > div {
    padding: 0 10px;
    flex-basis: 100%;

    &.image,
    &.image-left {
      padding: 20px 0 20px 20px;

      margin-top: auto;
      margin-bottom: auto;

      img {
        width: 100%;

        &:not(.no-shadow) {
          box-shadow: 4px 4px 8px 2px rgba(0, 0, 0, 0.3);
        }
      }
    }

    &.image-left {
      padding-right: 20px;
      padding-left: 0;
    }
  }
`;
