import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const Container = styled.div<{
  readonly wrapReverse?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: ${(props) => (props.wrapReverse ? 'wrap-reverse' : 'wrap')};

  width: 100%;
  max-width: ${({ theme }: { theme: Theme }) => theme.size.viewMaxWidth};

  @media (min-width: ${Breakpoints.medium}) {
    flex-wrap: nowrap;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  > div > div {
    margin: 0 30px;
  }
`;
