import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError } from '@dimatech/shared/lib/components/Alert';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

export const LoginWithLinkConfirmation = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { token = '' } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const { webToken, logout } = useContext(AuthenticationContext);

  const [isTokenInvalid, setIsTokenInvalid] = useState(false);

  useEffect(() => {
    // Force remove all previous tokens
    logout();

    if (!token) {
      return;
    }

    webToken.update(token);

    if (webToken.isValid()) {
      navigate((location.state as { from: string })?.from ?? '/search');
    } else {
      logout();

      setIsTokenInvalid(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (!isTokenInvalid) {
    return null;
  }

  return (
    <div style={{ marginBottom: 50 }}>
      <AlertError style={{ marginBottom: 30 }}>
        {t('LoginWithLink.ValidationError.InvalidToken')}
      </AlertError>

      <Link
        to="/login"
        state={{
          from: (location.state as { from: string })?.from ?? '/',
        }}
      >
        {t('Login.RequestNewWebLoginToken')}
      </Link>
    </div>
  );
};

LoginWithLinkConfirmation.displayName = 'LoginWithLinkConfirmation';
