import { css } from 'styled-components';

export const generalStyling = css`
  body {
    // Force scrollbar to prevent layout shift when navigating between pages
    overflow-y: scroll;

    > div:first-of-type {
      height: 100%;

      > div:first-of-type {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;
