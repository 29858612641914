import { axiosBaseQueryWithReAuthentication } from '@dimatech/features-core/lib/middleware/axiosBaseQueryWithReAuthentication';
import { initializeAxiosRequestInterceptor } from '@dimatech/features-core/lib/middleware/axiosRequestInterceptor';
import { initializeAxiosConfig } from '@dimatech/shared/lib/middleware/axiosConfig';
import { initializeAxiosResponseInterceptor } from '@dimatech/shared/lib/middleware/axiosResponseInterceptor';
import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from 'config';

export enum PrivateCacheTags {
  Search = 'Search',
  Followed = 'Followed',
  Contacted = 'Contacted',
  Project = 'Project',
  CommonTag = 'CommonTag',
  PublishedCustomer = 'PublishedCustomer',
}

initializeAxiosConfig({ ...config.axiosDefaults });
initializeAxiosRequestInterceptor();
initializeAxiosResponseInterceptor();

// Api
export const piosPrivateApi = createApi({
  reducerPath: 'piosPrivateApi',
  keepUnusedDataFor: 3600,
  tagTypes: [Object.values(PrivateCacheTags).join(',')],
  baseQuery: axiosBaseQueryWithReAuthentication({
    baseUrl: config.apiUrl.product.current,
  }),
  endpoints: () => ({}),
});
