import { useTitle } from '@dimatech/shared/lib/hooks';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const View = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}): JSX.Element => {
  useTitle(title);

  return (
    <ViewWrapper>
      <Header />

      <div>{children}</div>

      <Footer />
    </ViewWrapper>
  );
};

View.displayName = 'View';

export const ViewCentered = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}): JSX.Element => {
  useTitle(title);

  return (
    <ViewWrapperCentered>
      <Header />

      <div style={{ width: 400 }}>{children}</div>

      <Footer />
    </ViewWrapperCentered>
  );
};

ViewCentered.displayName = 'ViewCentered';

export const ViewWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    gap: 15px;
    flex-grow: 1;
    justify-content: flex-start;
  }

  > div:first-of-type,
  > div:last-of-type {
    flex-grow: 0;
    // justify-content: center;
  }
`;

export const ViewWrapperCentered = styled(ViewWrapper)`
  height: 100%;
  //  justify-content: center;
  align-items: center;

  > div {
    justify-content: center;
  }
`;
