import { appActions } from '@dimatech/features-core/lib/api/appSlice';
import { useRefreshAuthTokenMutation } from '@dimatech/features-core/lib/api/authentication/authenticationApi';
import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { AlertError, AlertInfo } from '@dimatech/shared/lib/components/Alert';
import { Input, Label } from '@dimatech/shared/lib/components/form';
import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useLazyRequestWebLoginTokenQuery } from 'api/private/loginApi';
import { Button } from 'components/Button';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { config } from 'config';
import { useAppDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isValidEmail } from 'utils';

export const LoginWithLink = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const publishPubliclyMinProjectsRequirement =
    useFlags()[flags.permanent.app.pios.publishPubliclyMinProjectsRequirement];

  const { accessToken, refreshToken, webToken, logout } = useContext(
    AuthenticationContext
  );

  const state: { email: string } = location.state as { email: string };

  const [requestWebLoginToken, { error, isSuccess, isLoading }] =
    useLazyRequestWebLoginTokenQuery();
  const [refreshAuthToken] = useRefreshAuthTokenMutation();

  const [email, setEmail] = useState(state?.email || '');

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      return;
    }

    requestWebLoginToken(email);
  };

  const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const target = event.currentTarget;

    setEmail(target.value.trim());
  };

  useEffect(() => {
    // Check if refresh token is set and valid, then we are logged in
    if (refreshToken.isValid()) {
      // Check if token is set and is valid, then we are
      // authed within this app and can redirect
      if (accessToken.isValid()) {
        handleLoggedIn();

        return;
      }

      // If auth token is missing or invalid, request a
      // token using refresh token
      refreshAuthToken({ refreshToken: refreshToken.token as string })
        .unwrap()
        .then((result) => {
          accessToken.update(result.accessToken);
          refreshToken.update(result.refreshToken);

          handleLoggedIn();
        })
        .catch(() => {
          // Ignore error, just logout
          logout();
        });
    }

    if (webToken.isValid()) {
      handleLoggedIn();

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(appActions.resetApplication());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoggedIn = () => {
    navigate((location.state as { from: string })?.from ?? '/search');
  };

  return (
    <Section style={{ marginTop: 30, marginBottom: 30 }}>
      <Container>
        <div>
          <form onSubmit={handleSubmit}>
            <Heading2>{t('LoginWithLink.Title')}</Heading2>

            <div style={{ marginTop: 20, marginBottom: 10 }}>
              {t('LoginWithLink.Text')}
            </div>

            <LinkContainer style={{ marginBottom: 30 }}>
              <Link
                to="/login-with-account"
                state={{
                  from: (location.state as { from: string })?.from ?? '/',
                }}
              >
                {t('LoginWithLink.HaveAccount')}
              </Link>
            </LinkContainer>

            {error && (
              <Error style={{ marginBottom: 30 }}>
                {t(`Login.ValidationError.${error.name}`, {
                  publishPubliclyMinProjectsRequirement,
                })}
              </Error>
            )}

            {isSuccess && (
              <AlertInfo style={{ marginBottom: 30 }}>
                {t(`LoginWithLink.EmailSent`, { email })}
              </AlertInfo>
            )}

            {!isSuccess && (
              <>
                <div>
                  <Label htmlFor="login">{t('Login.Login')}</Label>
                  <Input
                    type="text"
                    id="login"
                    name="login"
                    defaultValue={email}
                    onChange={handleChange}
                  />
                </div>

                <Button
                  handleClick={handleSubmit}
                  style={{ width: '100%', marginTop: 20, marginBottom: 20 }}
                  disabled={isLoading}
                  text={
                    isLoading
                      ? t('LoginWithLink.Submitting')
                      : t('LoginWithLink.Submit')
                  }
                />
              </>
            )}

            <LinkContainer>
              <a href={`${config.products.pios.url}/register`}>
                {t('Login.Register')}
              </a>
            </LinkContainer>
          </form>
        </div>
      </Container>
    </Section>
  );
};

LoginWithLink.displayName = 'LoginWithLink';

const LinkContainer = styled.div`
  margin-top: 15px;
`;

const Error = styled(AlertError)`
  margin: 15px 0 10px;
`;
