import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const HeroText = styled.div`
  background-color: rgba(63, 61, 61, 0.7);
  color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};

  font: ${({ theme }: { theme: Theme }) => theme.fonts.title};
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};

  max-width: 380px;
  line-height: 20px;

  padding: 10px;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.base};
    max-width: 580px;
  }

  @media (min-width: ${Breakpoints.large}) {
    max-width: 880px;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    margin-left: 0;
  }
`;

export const HeroSubTitle = styled(HeroText)`
  @media (min-width: ${Breakpoints.medium}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.l};
  }

  @media (min-width: ${Breakpoints.large}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
    line-height: 30px;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    margin-left: 0;
  }
`;

export const HeroTitle = styled(HeroText)`
  max-width: fit-content;
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.l};

  @media (min-width: ${Breakpoints.medium}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
  }

  @media (min-width: ${Breakpoints.large}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xxl};
  }

  @media (min-width: ${Breakpoints.xxl}) {
    margin-left: 0;
  }
`;

export const HeroTagLine = styled(HeroSubTitle)`
  margin-left: 30px !important;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xl};
    max-width: 560px;
    line-height: 30px;

    padding: 15px;
    margin-top: 100px;
  }

  @media (min-width: ${Breakpoints.large}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xxl};
    max-width: 800px;
    line-height: 45px;

    padding: 20px;
    margin-top: 200px;
  }

  @media (min-width: ${Breakpoints.xxl}) {
    margin-left: 0;
  }
`;
