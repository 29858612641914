import { PublicCacheTags, piosPublicApi } from 'api/piosPublicApi';
import { Overview, OverviewByTag } from 'models';

const overviewApi = piosPublicApi.injectEndpoints({
  endpoints: (build) => ({
    getOverview: build.query<Overview, void>({
      query: () => `web/projects/overview`,
      providesTags: [PublicCacheTags.Overview],
    }),

    getOverviewByTag: build.query<OverviewByTag[], void>({
      // cspell: disable-next-line
      query: () => `web/projects/overview/bytag`,
      providesTags: [PublicCacheTags.OverviewByTag],
    }),
  }),
});

export const { useGetOverviewQuery, useGetOverviewByTagQuery } = overviewApi;
