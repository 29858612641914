import { piosPrivateApi } from 'api/piosPrivateApi';

const loginApi = piosPrivateApi.injectEndpoints({
  endpoints: (build) => ({
    /**
     * Request web login token
     */
    requestWebLoginToken: build.query<void, string>({
      query: (email: string) => `web/users/login?email=${email}`,
    }),
  }),
});

export const { useLazyRequestWebLoginTokenQuery } = loginApi;
