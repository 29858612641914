import { Search as SearchFeature } from 'features/projects/Search';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const Search = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View title={t('Search.Title')}>
      <SearchFeature />
    </View>
  );
};

Search.displayName = 'Search';
