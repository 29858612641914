import { AuthenticationContext } from '@dimatech/features-core/lib/features/authentication';
import { LoaderOverlay } from '@dimatech/shared/lib/components/loader';
import {
  Pagination,
  useSortablePaginator,
} from '@dimatech/shared/lib/components/paginator';
import {
  Table,
  TableResponsiveContainer,
  Td,
  TdRight,
  Th,
  ThRight,
  Tr,
} from '@dimatech/shared/lib/components/table';
import { useLazyGetContactedQuery } from 'api/private/contactApi';
import img_hero from 'assets/images/nature-1-hero.jpg';
import { Container } from 'components/Container';
import { HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { Section } from 'components/Section';
import { parseISO } from 'date-fns';
import { Paginator, SortDirection } from 'models';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatDate } from 'utils';

export const ContactedProjects = (): JSX.Element => {
  const { t } = useTranslation();
  const { accessToken, webToken } = useContext(AuthenticationContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialPaginator: Paginator = {
    page: 1,
    pageSize: 25,
    orderBy: 'title',
    orderDirection: SortDirection.Asc,
  };

  const [search, { data, isFetching, isLoading }] = useLazyGetContactedQuery();

  const handleGet = (paginator: Paginator = initialPaginator) => {
    if (!accessToken?.user?.id && !webToken.token) {
      return;
    }

    search(
      {
        paginator,
        _userId: accessToken?.user?.id ?? webToken.token ?? '',
      },
      true
    );
  };

  const { setPage, paginator, sorter } = useSortablePaginator({
    ...initialPaginator,
    handlePaginatorChange: handleGet,
  });

  useEffect(() => {
    handleGet();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeroProduct image={img_hero} style={{ minHeight: 180 }}>
        <HeroTitle>{t('ContactedProjects.Title')}</HeroTitle>
        <HeroText>{t('ContactedProjects.Text')}</HeroText>
      </HeroProduct>

      <Section style={{ marginBottom: 50 }}>
        <Container>
          <Style>
            {(isFetching || isLoading) && (
              <div style={{ margin: '40px 0' }}>
                <LoaderOverlay>{t('Common.UI.Loading')}</LoaderOverlay>
              </div>
            )}

            {data && data.totalRecords === 0 && (
              <div style={{ marginTop: 20 }} className="i">
                {t('ContactedProjects.NoProjects')}
              </div>
            )}

            {data && data.totalRecords > 0 && (
              <>
                <Pagination
                  currentPage={paginator.page}
                  totalCount={data?.totalRecords ?? 0}
                  pageSize={paginator.pageSize}
                  handlePageChange={(page) => setPage(page)}
                  style={{ marginTop: 40 }}
                />

                <TableResponsiveContainer style={{ marginTop: 40 }}>
                  <Table>
                    <thead>
                      <tr>
                        <Th sortKey="customerName" sorter={sorter}>
                          {t('Project.OrganisationName')}
                        </Th>
                        <Th sortKey="title" sorter={sorter}>
                          {t('Project.Title')}
                        </Th>
                        <Th sortKey="projectPhase" sorter={sorter}>
                          {t('Project.ProjectPhase')}
                        </Th>
                        <ThRight sortKey="contactedDate" sorter={sorter}>
                          {t('Project.ContactedDate')}
                        </ThRight>
                      </tr>
                    </thead>
                    <tbody>
                      {data.records.map((project) => (
                        <Tr
                          key={project.projectId}
                          isHoverable={true}
                          onSelect={() => {
                            navigate(`/project/${project.projectId}`, {
                              state: { from: location.pathname },
                            });
                          }}
                        >
                          <Td>{project.customerName}</Td>
                          <Td>{project.title}</Td>
                          <Td>{t(`Project.Phase.${project.projectPhase}`)}</Td>
                          <TdRight>
                            {project.contactedDate
                              ? formatDate(parseISO(project.contactedDate))
                              : '-'}
                          </TdRight>
                        </Tr>
                      ))}
                    </tbody>
                  </Table>
                </TableResponsiveContainer>

                <Pagination
                  currentPage={paginator.page}
                  totalCount={data?.totalRecords ?? 0}
                  pageSize={paginator.pageSize}
                  handlePageChange={(page) => setPage(page)}
                  style={{ marginTop: 40 }}
                />
              </>
            )}
          </Style>
        </Container>
      </Section>
    </>
  );
};

ContactedProjects.displayName = 'ContactedProjects';

const Style = styled.div`
  padding-top: 30px;
`;
