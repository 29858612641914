import { flags } from '@dimatech/shared/lib/feature-flags';
import { Home as HomeFeature } from 'features/home';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { View, ViewWrapperCentered } from 'views';

export const Home = (): JSX.Element | null => {
  const { t } = useTranslation();

  const isPublicWebEnabledFlagOn =
    useFlags()[flags.permanent.app.pios.isPublicWebEnabled];

  if (!isPublicWebEnabledFlagOn) {
    return (
      <NotReleased title={t('Home.Title')}>
        <div className="size-l">
          <p>
            Pios.se är en databas där offentlig sektor delar sina
            utvecklingsprojekt.
          </p>
          <p>
            Genom att alla delar sina projekt så faciliterar vi gemensamt
            lärande vilket driver en mer effektiv och ändamålsenlig utveckling
            och digitalisering. Gemensamt undviker vi att upprepa misstag, men
            framförallt kan fler inspireras och tillsammans driva framgångsrika
            initiativ.
          </p>
          <p>Pios.se är en resurs för dig som arbetar inom offentlig sektor.</p>
          <p>För att få access krävs två saker,</p>
          <ol>
            <li>
              Din arbetsgivare behöver ha en Pios-licens (baslicens, eller
              vanlig)
            </li>
            <li>Din arbetsgivare behöver själv dela minst 15 projekt</li>
          </ol>
          <p>
            Alla som arbetar i en organisation där ovan två kriterier är
            uppfyllda, har obegränsad access till Pios.se.
          </p>

          <p className="b">Pios.se lanseras 11 september, vi ses då.</p>
        </div>
      </NotReleased>
    );
  }

  return (
    <View title={t('Home.Title')}>
      <HomeFeature />
    </View>
  );
};

Home.displayName = 'Home';

const NotReleased = styled(ViewWrapperCentered)`
  > div {
    max-width: 700px;
    margin-bottom: 100px;
    margin-top: 20px;
    padding: 20px;

    > p {
      margin: 10px 0;
    }
  }
`;
