import { ThemeGuide } from '@dimatech/shared/lib/components/theme-guide';
import { Theme } from '@dimatech/shared/lib/themes';
import { Container } from 'components/Container';
import { Section } from 'components/Section';
import { withTheme } from 'styled-components';
import { View } from 'views';

export const StyleGuide = withTheme(
  ({ theme }: { theme: Theme }): JSX.Element | null => {
    return (
      <View title="Style guide">
        <Section>
          <Container>
            <ThemeGuide theme={theme} />
          </Container>
        </Section>
      </View>
    );
  }
);

StyleGuide.displayName = 'StyleGuide';
