import { config } from '@dimatech/shared/lib/config';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState: {
  isPosting: boolean;
  error?: string | null;
} = {
  isPosting: false,
  error: null,
};

export const getLogo = createAsyncThunk(
  "customer/logo'",
  async ({ customerId }: { customerId: string }) => {
    return axios({
      url: `${config.apiUrl.product.current}web/customers/${customerId}/logo`,
      method: 'GET',
      responseType: 'blob', // important
      timeout: 60000,
    }).then((response) => {
      const svg = new Blob([response.data], { type: 'image/svg+xml' });

      return URL.createObjectURL(svg);
    });
  }
);

export const { reducer: customerLogoReducer, actions: customerLogoActions } =
  createSlice({
    name: 'customer/logo',
    initialState,
    reducers: {},
    extraReducers: (build) => {
      build
        .addCase(getLogo.fulfilled, (state) => {
          state.isPosting = false;
        })
        .addCase(getLogo.pending, (state) => {
          state.isPosting = true;
          state.error = null;
        })
        .addCase(getLogo.rejected, (state, action) => {
          state.isPosting = false;
          state.error = action.error.name;
        });
    },
  });

export const selectLogoError = (state: {
  customer: { logo: { error: string } };
}): string | null | undefined => state.customer.logo.error;

export const selectLogoIsPosting = (state: {
  customer: { logo: { isPosting: boolean } };
}): boolean => state.customer.logo.isPosting;
