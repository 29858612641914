import { ContactedProjects as ContactedProjectsFeature } from 'features/projects/ContactedProjects';
import { useTranslation } from 'react-i18next';
import { View } from 'views';

export const ContactedProjects = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View title={t('ContactedProjects.Title')}>
      <ContactedProjectsFeature />
    </View>
  );
};

ContactedProjects.displayName = 'ContactedProjects';
